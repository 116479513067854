import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { iconCancel, iconSave } from "app/common/utils/icons.utils";
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectListitem } from 'app/common/models/models';

@Component({
    selector: 'survey-producers',
    templateUrl: './survey-producers.modal.html'
})
export class SurveyProducersModalPage implements OnInit {
	iconCancel = iconCancel;
	iconSave = iconSave;
	public entityForm: FormGroup = null;	
	data: {
		survey: any
	};
	responses: SelectListitem[] = [
		{id: 0, label: "strongly disagree"},
		{id: 1, label: "disagree"},
		{id: 2, label: "neutral"},
		{id: 3, label: "agree"},
		{id: 4, label: "strongly agree"}
	];

	public get title() {
		return  "Agroecology Questionnaire";
	}

	get formValid() {
		return this.entityForm && this.entityForm.valid;
	}

	ngOnInit() {
		this.initForm();	
	}
	
    constructor(
		private activeModal: NgbActiveModal,
		private _fb: FormBuilder
		) {
			
    }

	
	private initForm = () => {
		let entityFormDef: any = {
			q1: [null, CommonValidators.required],
			q2: [null, CommonValidators.required],
			q3: [null, CommonValidators.required],
			q4: [null, CommonValidators.required],
			q5: [null, CommonValidators.required],
			q6: [null, CommonValidators.required],
			q7: [null, CommonValidators.required],
			q8: [null, CommonValidators.required],
			q9: [null, CommonValidators.required],
			q10: [null, CommonValidators.required],
			q11: [null, CommonValidators.required],
			q12: [null, CommonValidators.required],
			q13: [null, CommonValidators.required],
			q14: [null, CommonValidators.required],
			q15: [null, CommonValidators.required]
		};
		this.entityForm = this._fb.group(entityFormDef);
		this.entityForm.patchValue(this.data.survey);
	}


    public save() {
        if (this.entityForm.valid) {
			this.activeModal.close(this.entityForm.value);
        }
    }

	
	public get canSaveForm(): boolean {
		return this.entityForm && this.entityForm.valid;
	}

	public close = (): void => {
		this.activeModal.close(false);
    }



}


