import { Component, forwardRef, Input, Optional, Host, SkipSelf, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInputComponent } from './base-input.component';

@Component({
    selector: 'radio',
    templateUrl: './radio.component.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RadioComponent),
        multi: true,
    }]
})

export class RadioComponent extends BaseInputComponent<any, any> {
    @Input() public items: any[] = [];
    @Input() public itemKey: string = "id";
    @Input() public itemLabel: string = "label";
    @Input() public showPlaceHolder: boolean = true;
    
	checkedItems = [];

	protected initialize = (): void => {
		console.log("initialize");
		
		this.checkedItems = [];
		this.items.forEach((el) => {
			this.checkedItems.push(el.id == this.value);
		})
		// console.log("this.checkedItems", this.checkedItems);
		
	}

    constructor(@Optional() @Host() @SkipSelf() controlContainer: ControlContainer, translateService: TranslateService) {
        super(controlContainer, translateService);
        this.placeHolder = "common.select";
    }

    protected toInternalFormat(value: any): any {
		// console.log("toInternalFormat " + value);
		this.checkedItems = [];
		this.items.forEach((el) => {
			this.checkedItems.push(el.id == value);
		})
		// console.log("this.checkedItems", this.checkedItems);

        return value;
    }

    protected toExternalFormat(value: any): any {
		// console.log("toExternalFormat " + value);
        if (value === "") {
            return null;
        }
        return value;
    }

	onItemChange($event, item) {
		// console.log("onItemChange " + $event.target.value, $event, item);
		if ($event.target.value == "on") {
			this.value = item.id;
		}
		
	}
	
}
